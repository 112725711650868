<template>
  <div class="product-category-form">
    <b-card>
      <!-- Name -->
      <b-form-group
        v-for="locale in locales"
        :key="locale"
      >
        <label>Name</label> <span class="badge badge-dark">{{ locale }}</span>
        <b-form-input
          v-model="internalValue[`name_${locale}`]"
          @input="emitInput"
        />
      </b-form-group>

      <!-- Slug -->
      <b-form-group>
        <label>Slug</label>
        <b-form-input
          v-model="internalValue.slug"
          @input="emitInput"
        />
      </b-form-group>

      <!-- Image -->
      <b-form-group>
        <label>Image</label>
        <file-selector
          v-model="internalValue.image"
        />
      </b-form-group>
    </b-card>
  </div>
</template>

<script>
import FileSelector from './FileSelector.vue'

export default {
  name: 'ProductCategoryForm',

  components: {
    FileSelector
  },

  props: {
    value: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      internalValue: {
        name_th: '',
        name_en: '',
        image: '',
        image_alt_th: '',
        image_alt_en: ''
      }
    }
  },

  created () {
    if (this.value) {
      this.internalValue = JSON.parse(JSON.stringify(this.value))
    }
    this.emitInput()
  },

  methods: {
    emitInput () {
      this.$emit('input', this.internalValue)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
