<template>
  <div class="product-category-create-page">
    <h2>Create Product Category</h2>

    <product-category-form
      v-model="form"
      class="my-3"
    />

    <b-button @click="submit">Save</b-button>
  </div>
</template>

<script>
import ProductCategoryForm from '../components/ProductCategoryForm.vue'

export default {
  name: 'ProductCategoryCreate',

  components: {
    ProductCategoryForm
  },

  data () {
    return {
      form: null
    }
  },

  methods: {
    validate () {
      let valid = true

      return valid
    },

    async submit () {
      if (!this.validate()) {
        console.warn('Invalid form')
        return
      }

      try {
        const resp = await this.$categories.create(this.form)
        const category = resp.data.data
        this.$router.push({
          name: 'category-edit',
          params: {
            id: category.id
          }
        })
      }
      catch (e) {
        console.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
