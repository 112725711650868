<template>
  <div class="file-selector">
    <b-row
      class="row mb-2"
    >
      <b-col
        v-for="(item, i) in internalValue"
        :key="i"
        :md="gridColumnSize"
      >
        <a
          :href="getFullUrl(item.path)"
          target="blank"
        >
          <image-background
            :src="getFullUrl(item.path)"
            :aspect="16/9"
            class="mb-3"
          />
        </a>

        <div
          v-if="!hideAlternateText"
          class="alternate-text-wrapper"
        >
          <b-form-group
            v-for="locale in locales"
            :key="`alt_${locale}`"
          >
            <label>Alternate Text</label> <span class="badge badge-dark">{{ locale }}</span>
            <b-form-input
              v-model="item[`alt_${locale}`]"
              @input="emitInput"
            />
          </b-form-group>
        </div>

        <b-form-group>
          <b-button
            variant="danger"
            @click="removeItemAtIndex(i)"
          >
            Remove
          </b-button>
        </b-form-group>
      </b-col>

      <b-col
        v-if="multiple || internalValue.length === 0"
      >
        <b-button
          @click="openFileBrowser"
        >
          {{ buttonText }}
        </b-button>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import ImageBackground from './ImageBackground.vue'

export default {
  name: 'FileSelector',

  components: {
    ImageBackground
  },

  props: {
    value: {
      type: [String, Object, Array],
      default: null
    },

    buttonText: {
      type: String,
      default: 'Select file'
    },

    type: {
      type: String,
      default: 'image'
    },

    multiple: {
      type: Boolean,
      default: false
    },

    hideAlternateText: {
      type: Boolean,
      default: false
    },

    columns: {
      type: Number,
      default: 4
    },

    pathFieldName: {
      type: String,
      default: 'path'
    },

    altFieldName: {
      type: String,
      default: 'alt'
    }
  },

  computed: {
    isImage () {
      return this.type === 'image'
    },

    gridColumnSize () {
      return 12 / this.columns
    }
  },

  data () {
    return {
      internalValue: []
    }
  },

  mounted () {
    // Store local value as an array.
    if (this.value) {
      if (Array.isArray(this.value)) {
        this.internalValue = JSON.parse(JSON.stringify(this.value))
      }
      // Convert string path to object.
      else if (typeof this.value === 'string') {
        const value = { path: this.value }
        for (let locale of this.locales) {
          value[`alt_${locale}`] = ''
        }
        this.internalValue = [value]
      }
      else {
        this.internalValue = [this.value]
      }
    }

    this.emitInput()
  },

  methods: {
    openFileBrowser () {
      this.$store.dispatch('modal/openFileBrowser', {
        callback: this.selectFiles,
        multiple: this.multiple,
        type: this.type
      })
    },

    selectFiles (files) {
      if (files.length === 0) {
        return
      }

      // Clear all if it is single.
      if (!this.multiple) {
        this.clear()
      }

      const formattedFiles = files.map(file => {
        const data = { path: file.path }
        for (let locale of this.locales) {
          data[`alt_${locale}`] = ''
        }
        return data
      })

      this.internalValue.push(...formattedFiles)
      this.emitInput()
    },

    clear () {
      this.internalValue = []
      this.emitInput()
    },

    removeItemAtIndex (index) {
      this.internalValue.splice(index, 1)
      this.emitInput()
    },

    emitInput () {
      if (this.multiple) {
        this.$emit('input', this.hideAlternateText ? this.internalValue.map(item => item.path) : this.internalValue)
      }
      else {
        const value = this.internalValue[0]
        this.$emit('input', this.hideAlternateText ? (value ? value.path : null) : value)
      }
    },

    getFullUrl (path) {
      return `${process.env.VUE_APP_MEDIA_URL}/${path}`
    }
  }
}
</script>

<style lang="scss" scoped>
.file-preview {
  width: 200px;
  height: 200px;
  border: 1px solid #efefef;
  border-radius: 4px;
  display: block;
}
</style>
