<template>
  <b-aspect
    :aspect="aspect"
    class="image-background"
    :style="cssStyle"
  >
    <slot />
  </b-aspect>
</template>

<script>
export default {
  name: 'ImageBackground',

  props: {
    aspect: {
      type: Number,
      default: 1
    },

    src: {
      type: String,
      required: true
    }
  },

  computed: {
    cssStyle () {
      return {
        backgroundImage: `url('${this.src}')`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
